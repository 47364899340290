import { Button, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import api from "../../../api/api";
import { useQueryClient } from "react-query";

const HubImageUpload = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData("currentUser");
  const hubId = currentUser?.hub?.hub?.hub_id;

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt200KB = file.size / 1024 < 200;
    if (!isLt200KB) {
      message.error("Image must be smaller than 200KB!");
    }
    return isJpgOrPng && isLt200KB;
  };

  const handleChange = ({ file, fileList }) => {
    if (file.status === "done" || file.status === "uploading") {
      setFileList(
        fileList.map((file) => {
          if (file.originFileObj) {
            return {
              ...file,
              url: URL.createObjectURL(file.originFileObj),
            };
          }
          return file;
        })
      );
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("images", file.originFileObj);
      });

      const response = await api.uploadHubPictures(hubId, formData);
      console.log({ response });
    } catch (error) {
      message.error("Failed to submit support request.");
    }
    form.resetFields();
    setFileList([]);
    setLoading(false);
  };

  return (
    <div className="hubImage-upload">
      <p>Let’s start with some pictures of your shared home</p>
      <Form.Item
        getValueFromEvent={(event) => {
          console.log({ event });
          return event?.fileList;
        }}
        name="images"
      >
        <Upload
          accept=".jpeg, .jpg, .png"
          listType="picture"
          maxCount={5}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          fileList={fileList}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok");
            }, 0);
          }}
          onRemove={(file) => {
            setFileList(fileList.filter((item) => item.uid !== file.uid));
          }}
        >
          <Button className="upload-btn" icon={<UploadOutlined />}>
            <span className="upload">Click to upload (Max: 5)</span>
            <span className="upload-condition">PNG or JPG (max: 200kb)</span>
          </Button>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default HubImageUpload;
