import "leaflet/dist/leaflet.css";
import { useEffect } from "react";
import { Marker, useMap } from "react-leaflet";

const Map = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.setView([position.latitude, position.longitude], 12);
    }
  }, [position, map]);

  return (
    <>
      {position && (
        <Marker position={[position.latitude, position.longitude]} />
      )}
    </>
  );
};

export default Map;
