import { Button, Checkbox, Col, Form, Input, Radio, Row, Slider } from "antd";
import locationSvg from "../../../assets/images/SVG/location.svg";
import LocationInput from "./LocationInput.jsx";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

export const CustomRadioGroup = ({
  options,
  form,
  name,
  onChange,
  initialValue,
  rentStatusButton,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [rentStatus, setRentStatus] = useState("not included in rent");

  const className = `radio-button-group ${
    options?.length <= 3
      ? "radio-flex"
      : options?.length > 6
      ? "radio-super-grid"
      : "radio-grid"
  }`;

  const handleRadioChange = (e) => {
    if (rentStatusButton) {
      const value = e.target.value;
      setSelectedOption(value);
      // const isOptionSelected = value !== "none";
      // const isIncludedInRent = rentStatus === "included in rent";

      // const newValue = {
      //   choice: value,
      //   enable: isOptionSelected,
      //   included_in_rent: isOptionSelected && isIncludedInRent,
      // };

      // form.setFieldValue(name, newValue);
      // onChange(e, name);
    }
    onChange(e, name);
  };

  const toggleRentStatus = (e) => {
    setRentStatus((prevStatus) =>
      prevStatus === "not included in rent"
        ? "included in rent"
        : "not included in rent"
    );
    // if (selectedOption !== null) {
    // const isOptionSelected = selectedOption !== "none";
    // const isIncludedInRent = rentStatus !== "included in rent"; // because toggle changes the status after setting

    // const newValue = {
    //   choice: selectedOption,
    //   enable: isOptionSelected,
    //   included_in_rent: isOptionSelected && isIncludedInRent,
    // };

    // form.setFieldValue(name, newValue);
    // }
  };

  return (
    <Radio.Group
      className={className}
      onChange={handleRadioChange}
      value={form.getFieldValue(name) ?? initialValue}
    >
      {options?.map((item) => {
        const isSelected =
          selectedOption === item?.value && item?.value !== "none";
        const buttonClassName = `radio-button ${
          options?.length <= 3 ? "button-big" : "button-small"
        } ${isSelected && "adjusted-border"}`;
        return (
          <div className="radio-button-container" key={item?.value}>
            <Radio.Button
              className={buttonClassName}
              key={item?.value}
              value={item?.value}
            >
              {item?.display}
            </Radio.Button>
            {rentStatusButton && isSelected && (
              <Button onClick={toggleRentStatus} className="rent-status-button">
                {rentStatus}
              </Button>
            )}
          </div>
        );
      })}
    </Radio.Group>
  );
};

export const MultiSelectOptions = ({
  options,
  onChange,
  name,
  initialValue,
  form,
}) => {
  const className = `checkbox-group ${
    options?.length <= 3
      ? "checkbox-flex"
      : options?.length > 6
      ? "checkbox-super-grid"
      : "checkbox-grid"
  }`;
  return (
    <Checkbox.Group
      className={className}
      options={options}
      value={form.getFieldValue(name) ?? initialValue}
      onChange={(checkedValues) => onChange(checkedValues, name)}
    ></Checkbox.Group>
  );
};

export const FormInput = ({
  label,
  name,
  initialValue,
  placeholder,
  isTextArea,
  isLocation,
  form,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        initialValue={initialValue}
        className={
          isTextArea ? "input-textArea" : isLocation ? "input-location" : ""
        }
      >
        {isTextArea ? (
          <Input.TextArea
            autoSize={{
              minRows: 5,
            }}
            showCount
            maxLength={150}
            placeholder={placeholder}
          />
        ) : isLocation ? (
          <LocationInput
            form={form}
            placeholder={placeholder}
            isLocation={isLocation}
            locationSvg={locationSvg}
          />
        ) : (
          <Input placeholder={placeholder} />
        )}
      </Form.Item>
    </>
  );
};

export const CustomSlider = ({
  marks,
  initialValue,
  max,
  onChange,
  form,
  name,
}) => {
  return (
    <Slider
      onChange={(e) => onChange(e, name)}
      max={max}
      min={0}
      className="point-slider custom-slider"
      marks={marks}
      value={form?.getFieldValue(name) ?? initialValue}
    />
  );
};

export const AddInputs = ({ form }) => {
  return (
    <>
      <p>Enter places of interest within a 5km radius:</p>
      <Form.List name="place_of_interest" initialValue={["", "", ""]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div className="removable-item" key={key}>
                <Form.Item
                  {...restField}
                  className="place-input additional"
                  name={name}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a place of interest",
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      index === 0
                        ? "Aldi’s Supermarket"
                        : index === 1
                        ? "Brunsen Park"
                        : index === 2
                        ? "WTC Metro station"
                        : "Enter a place of interest"
                    }
                  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </div>
            ))}
            {fields.length < 5 && (
              <Form.Item>
                <Button
                  className="add-input-btn"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                ></Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </>
  );
};
